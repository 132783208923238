<template>
    <div>
        <div class="flex items-center gap-2.5 my-2 pl-1" v-if="type==='radio'">
            <input
                type="radio"
                :id="id"
                :name="name"
                class="relative float-left h-4 w-4 appearance-none rounded-full border-2 border-solid border-primary-300 before:pointer-events-none before:absolute before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-3 after:w-3 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.425rem] checked:after:w-[0.425rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 dark:border-primary-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                :value="inputValue"
                v-model="model"
            >
            <label v-if="label" class="font-medium text-primary text-base" :for="id">{{label}}</label>
        </div>

        <RadioGroup v-if="type==='radio-card-description'" v-model="model">
            <RadioGroupLabel class="sr-only">
                {{label}}
            </RadioGroupLabel>
            <div class="space-y-4">
                <slot name="card-option-description"></slot>
            </div>
        </RadioGroup>

        <RadioGroup v-if="type==='radio-card'" v-model="model">
            <RadioGroupLabel class="sr-only">
                Pricing plans
            </RadioGroupLabel>
            <div class="relative space-y-3">
                <slot name="card-option"></slot>
            </div>
        </RadioGroup>

        <template v-if="type==='radio-selected'">
          <span class="w-full">
              <input type="radio" :value="inputValue" v-model="model" @change="handleChange" :name="name" :id="id">
              <label :for="id" :class="sizeClass">
                  <span v-if="label" class="whitespace-nowrap text-xs sm:text-sm text-primary">{{label}}</span>
                  <slot />
                  <i v-if="icon?.length>0" :class="icon"></i>
              </label>
          </span>
        </template>
    </div>
</template>

<script>
import {computed, getCurrentInstance, ref, nextTick, defineComponent} from '#imports'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default defineComponent({
    componentName: 'LbRadio',
    components: {
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
    },
    props:{
        type: String,
        size: String,
        modelValue : {
            default : null
        },
        inputValue : {
            default : false
        },
        label: String,
        name : String,
        id: [String, Number],
        icon : String,
    },
    setup(props, ctx) {

        const { emit } = getCurrentInstance()

        const model = computed({
            get() {
                return props.modelValue
            },
            set(val) {
                emit('update:modelValue', val)
            },
        })

        const sizeClass = computed(() => {
            if(props.size==='lg'){
                return 'sm:px-6 py-3 px-2'
            } else if(props.size==='md'){
                return 'sm:px-6 py-1 px-2'
            } else if(props.size==='sm'){
                return 'sm:px-6 py-0.5 px-2'
            }  else {
                return 'sm:px-3 py-2 px-2'
            }
        });

        function handleChange(e) {
            nextTick(() => {
                emit('change', model.value)
            })
        }

        return {
            model,
            sizeClass,
            handleChange
        }
    },
    computed:{

    }
})
</script>
